import {
  Mic,
  CalendarMonth,
  Help,
  Logout,
  MicNoneOutlined,
  HelpOutlineOutlined,
	SettingsOutlined,
	Settings,
} from "@mui/icons-material";
import type {SvgIconProps} from "@mui/material";
import React from "react";

export interface SideNavTab {
  name: string;
  path: string;
  icon: React.ComponentType<SvgIconProps>;
  activeIcon: React.ComponentType<SvgIconProps>;
  key: string;
}

export const SideNavTabs: SideNavTab[] = [
  {
    name: "Transcribe Now",
    path: "/home",
    icon: MicNoneOutlined,
    activeIcon: Mic,
    key: "home",
  },
  {
    name: "Appointments",
    path: "/appointments",
    icon: CalendarMonth,
    activeIcon: CalendarMonth,
    key: "appointments",
  },
  {
    name: "Patients",
    path: "/patients",
    icon: CalendarMonth,
    activeIcon: CalendarMonth,
    key: "patients",
  },
  {
    name: "Settings",
    path: "/settings",
    icon: SettingsOutlined,
    activeIcon: Settings,
    key: "settings",
  },
  {
    name: "Help & Support",
    path: "/help-and-support",
    icon: HelpOutlineOutlined,
    activeIcon: Help,
    key: "helpAndSupport",
  },
  {
    name: "Logout",
    path: "/logout",
    icon: Logout,
    activeIcon: Logout,
    key: "logout",
  },
];
