import {Box, IconButton, InputBase, Toolbar, styled} from "@mui/material";
import type {MouseEventHandler} from "react";
import {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import {navIcon, navIconContainer} from "./styles";
import WhiteAppBar from "./WhiteAppBar";
import theme from "../../assets/theme";
import {useNavigate} from "react-router-dom";

const Search = styled("div")(({theme}) => ({
  position: "relative",
  borderRadius: theme.borders.borderRadius.md,
  backgroundColor: theme.colors.tertiary.main,
  "&:hover": {
    backgroundColor: theme.colors.white.main,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: "90%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      width: "35ch",
    },
    [theme.breakpoints.down("lg")]: {
      width: "20ch",
    },
  },
}));

interface Props {
  drawerWidth: number;
  handleDrawerToggle: MouseEventHandler<HTMLButtonElement>;
}

const Navbar = ({drawerWidth, handleDrawerToggle}: Props): JSX.Element => {
  const [short, setShort] = useState(false);

  const handleScroll = (): void => {
    setShort(window.scrollY > 50);
  };

  const navigate = useNavigate();

  window.onscroll = handleScroll;
  return (
    <Box>
      <WhiteAppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          ml: {sm: `${drawerWidth}px`},
          backgroundColor: theme.colors.secondary.main,
        }}
        className={short ? "short" : ""}>
        <Toolbar sx={{display: "flex", justifyContent: "space-between"}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: "none"}}}>
            <MenuIcon />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase placeholder="Search…" inputProps={{"aria-label": "search"}} />
          </Search>
          <Box sx={navIconContainer()}>
            <IconButton>
              <NotificationsNoneIcon sx={theme => navIcon(theme)} />
            </IconButton>
            <IconButton>
              <AccountCircleIcon
                onClick={() => navigate("settings")}
                sx={theme => navIcon(theme)}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </WhiteAppBar>
    </Box>
  );
};

export default Navbar;
