import AxiosInstance from "../../axios/axiosInstance";
import {
  CHANGE_PASSWORD_API_URL,
  CONFIRM_FORGOT_PASSWORD_API_URL,
  FORGOT_PASSWORD_API_URL,
  LOGIN_API_URL,
  LOGOUT_API_URL,
  SEND_EMAIL_OTP_API_URL,
  SIGNUP_USER_API_URL,
  VERIFY_EMAIL_OTP_API_URL,
} from "../../constants/ApiUrls";
import type {
  LoginPayload,
  SendEmailOtpResponse,
  VerifyEmailOtpPayload,
  SignUpUserPayload,
  AuthUser,
  ForgotPasswordResponse,
  ConfirmForgotPasswordPayload,
  ChangePasswordPayload,
  ChangePasswordResponse,
} from "./auth.types";

export const login = async (payload: LoginPayload): Promise<AuthUser> => {
  const response = await AxiosInstance.post<AuthUser>(LOGIN_API_URL, payload);
  return response.data;
};

export const logout = async (): Promise<void> => {
  const response = await AxiosInstance.post<void>(LOGOUT_API_URL);
  return response.data;
};

export const sendEmailOtp = async (email: string): Promise<SendEmailOtpResponse> => {
  const response = await AxiosInstance.post<SendEmailOtpResponse>(SEND_EMAIL_OTP_API_URL, {
    email,
  });
  return response.data;
};

export const verifyEmailOtp = async (payload: VerifyEmailOtpPayload): Promise<object> => {
  const response = await AxiosInstance.post<object>(VERIFY_EMAIL_OTP_API_URL, payload);
  return response.data;
};

export const signUpUser = async (payload: SignUpUserPayload): Promise<AuthUser> => {
  const response = await AxiosInstance.post<AuthUser>(SIGNUP_USER_API_URL, payload);
  return response.data;
};

export const forgotPassword = async (email: string): Promise<ForgotPasswordResponse> => {
  const response = await AxiosInstance.post<ForgotPasswordResponse>(FORGOT_PASSWORD_API_URL, {
    email,
  });
  return response.data;
};

export const confirmForgotPassword = async (
  payload: ConfirmForgotPasswordPayload,
): Promise<ForgotPasswordResponse> => {
  const response = await AxiosInstance.post<ForgotPasswordResponse>(
    CONFIRM_FORGOT_PASSWORD_API_URL,
    payload,
  );
  return response.data;
};

export const changePassword = async (
  payload: ChangePasswordPayload,
): Promise<ChangePasswordResponse> => {
  const response = await AxiosInstance.post<ChangePasswordResponse>(
    CHANGE_PASSWORD_API_URL,
    payload,
  );
  return response.data;
};
