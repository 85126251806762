import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import type {Action, Auth, ReduxState} from "../interface";
import {AuthUser} from "../../api/auth/auth.types";
import {QueryClient} from "@tanstack/react-query";
import {NavigateFunction} from "react-router-dom";

const initialState: Auth = {
  isLoggedIn: false,
  user: undefined,
};

export const logoutUser = createAsyncThunk(
  "auth/logout",
  ({queryClient, navigate}: {queryClient: QueryClient; navigate: NavigateFunction}, {dispatch}) => {
    dispatch({type: "RESET_STATE"});
    queryClient.clear();
    navigate("/login");
  },
);

//Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state: Auth, action: Action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    authenticateSuccess: (state: Auth, action: Action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(logoutUser.fulfilled, state => {
      state.isLoggedIn = false;
      state.user = undefined;
    });
  },
});

export default authSlice.reducer;

//Actions
export const {loginSuccess, authenticateSuccess} = authSlice.actions;

//Selectors
export const selectIsLoggedIn = (state: ReduxState): boolean => state.auth.isLoggedIn;
export const selectUser = (state: ReduxState): AuthUser | undefined => state.auth.user;
