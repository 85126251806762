export const DOCTOR = "doctor";
export const NURSE = "nurse";
export const ECW = "ecw";
export const ATHENA = "athena";
export const PATIENT_QUERY_LIMIT = 8;
export const SEARCH_DEBOUNCE_TIME = 500;
export const TRANSCRIPTION_STATUS_POLL_TIME = 10000;
export const SOAP_NOTE_GENERATION_STATUS_POLL_TIME = 10000;
export const APPOINTMENT_TYPES = {
  ADHOC: "adhoc",
  EMR: "emr",
  ADHOC_EMR: "adhoc_emr",
  NONE: "none",
} as const;
export const ADHOC = "Adhoc";
export const NOT_RECORDED = "Not Recorded";
export const SELECTED_MICROPHONE = "selectedMicrophone";
export const APPOINTMENT_STATUS_DISPLAY = {
  scheduled: "Upcoming",
  cancelled: "Cancelled",
  in_progress: "In Progress",
  completed: "Completed",
  missed: "Missed",
  none: "None",
};
