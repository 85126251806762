import {Box, Tooltip, Typography} from "@mui/material";
import React from "react";
import {Appointment} from "../../../api/appointment/appointment.interface";
import {appointmentListItemContainer, appointmentListPatientName} from "./styles";
import {truncateSentence} from "../../../utils/StringConversions";
import {formatTimestampToTime} from "../../../utils/DateUtils";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../../redux/slice/authSlice";
import {ADHOC, APPOINTMENT_TYPES} from "../../../constants/Values";
import RecordingAnimation from "./../../../assets/animations/Recording.gif";
import {
  selectCurrentAppointmentId,
  selectIsRecording,
  showRecordWarningModal,
} from "../../../redux/slice/recordSlice";
import {useLocation, useNavigate} from "react-router-dom";

interface Props {
  appointment: Appointment;
}

const AppointmentListItem = ({appointment}: Props) => {
  const user = useSelector(selectUser);
  const isRecording = useSelector(selectIsRecording);
  const appointmentId = useSelector(selectCurrentAppointmentId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = location;
  const isCurrentAppointmentDetailPage =
    pathname.includes("appointment/") && parseInt(pathname.split("/")[2]) === appointment.id;

  const handleClick = (): void => {
    if (isRecording) {
      dispatch(showRecordWarningModal());
      return;
    }
    navigate(`/appointment/${appointment.id}`);
  };

  const patientName = appointment.patient
    ? appointment.patient.first_name + " " + appointment.patient.last_name
    : appointment.appointment_name;

  const getVisitType = (appointmentType: string, emr?: string) => {
    if (!user?.is_emr_linked) return "";
    const dot = ". ";
    switch (appointmentType) {
      case APPOINTMENT_TYPES.ADHOC:
        return `${dot}${ADHOC} Non EMR`;
      case APPOINTMENT_TYPES.ADHOC_EMR:
        return `${dot}${ADHOC} EMR`;
      case APPOINTMENT_TYPES.EMR:
        return `${dot}${emr} Visit`;
      default:
        return "";
    }
  };

  const appointmentTimeAndVisitType = `${formatTimestampToTime(Date.parse(appointment.appointment_time))} ${getVisitType(appointment.appointment_type, user?.emr_system_details?.emr_name)}`;

  return (
    <Box
      sx={theme => appointmentListItemContainer(theme, isCurrentAppointmentDetailPage)}
      onClick={handleClick}>
      <Box sx={{textOverflow: "ellipsis", whiteSpace: "normal", wordBreak: "break-word"}}>
        <Tooltip title={patientName}>
          <Typography style={appointmentListPatientName()}>
            {`${truncateSentence(patientName, 20)}`}
          </Typography>
        </Tooltip>
        <Typography variant="subtitle1" fontSize={"14px"} lineHeight="1.5">
          {appointmentTimeAndVisitType}
        </Typography>
      </Box>
      {!!isRecording && appointmentId === appointment.id && (
        <Box
          component={"img"}
          src={RecordingAnimation}
          sx={{height: "30px", width: "35px", ml: 0.5}}
        />
      )}
    </Box>
  );
};

export default AppointmentListItem;
