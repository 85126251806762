import {type Middleware, combineReducers, configureStore} from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import currentEditingSoapNoteReducer from "./slice/currentEditingSoapNoteSlice";
import {errorHandlerMiddleware} from "./middlewares/errorMiddleware";
import recordSlice from "./slice/recordSlice";

const appReducer = combineReducers({
  auth: authReducer,
  currentEditingSoapNote: currentEditingSoapNoteReducer,
  currentRecording: recordSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_STATE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorHandlerMiddleware as Middleware),
});

export type AppDispatch = typeof store.dispatch;

export default store;
